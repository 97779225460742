.timerContainer {
  width: 120px;
  height: 120px;
  text-align: center;
  font-size: 50px;
  line-height: 120px;
  font-weight: 900;
  position: absolute;
  top: 433px;
  font-family: ExoBold;
  color: white;
}

.right {
  right: 232px;
}
.left {
  left: 232px;
}

.vetoPickContainer {
  width: 430px;
  height: 125px;
  position: absolute;
  top: 433px;

}

.veto {
  position: absolute;
  height: 125px;
  width: 275px;
  background: url("src/assets/nationwars/VETO_BUTTON.png");
}

.pick {
  position: absolute;
  background: url("src/assets/nationwars/PICK_BUTTON.png");
  height: 125px;
  width: 275px;
  left: 153px;
}

.leftContainer {
  left: 79px;

}

.rightContainer {

  right: 79px;
}
