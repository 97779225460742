.photo {
  width: 100px;
}

.description {
  font-size: 20px;
  color: chartreuse;
}

.modal {
  width: 30%;
}

.cursorPointer {
  cursor: pointer;
}

.title {
  font-size: 30px;
  color: white;
  text-align: center;
}


.elementDisplayed {
  opacity: 0.8;
  border: 1px dashed green;
}
.elementNotDisplayed {
  opacity: 0.2;
  border: 1px dashed red;
}

.cardContainer {
  margin: 0 !important;
}

.cardContainer > img {
  filter: grayscale(1);
  width: 100%;
  height: 150px;
}

.cardContainer > img:hover {
  animation: colorTheCard 300ms linear;
  filter: grayscale(0);
  cursor: pointer;
  transform: scale(1.05);
}


