.pageContainer {
  background: #0e101c !important;
  padding-left: 310px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 400px;
}
.marginLeft {
  margin-left: 20px;
}
.marginRight {
  margin-right: 20px;
}

.link {
  text-decoration: none;
  color: white;

}
.width30 {
  width: 33%;
}
.width15 {
  width: 15%;
}

.cursorPointer {
  cursor: pointer;
}

.title {
  font-size: 30px;
  color: white;
  text-align: center;
}
