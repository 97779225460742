.selectedMapContainer {
  position: absolute;
  width: 80%;
  height: 10%;
  left: 10%;
  top: 46.5%;
  margin: auto;
  font-size: 55px;
  font-family: ExoBold;
  color: #22f0f1;
  z-index: 3;
}
