.photo {
  width: 100px;
}

.modal {
  width: 30%;
}

/* .membersListContainer {
  width: 200%;
} */

.cursorPointer {
  cursor: pointer;
}

.title {
  font-size: 30px;
  text-align: center;
  color: white;
}


.elementDisplayed {
  opacity: 0.8;
}
.elementNotDisplayed {
  opacity: 0.2;
}
