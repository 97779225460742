.pageContainer {
  background: transparent !important;
  width: 1920px;
  height: 1080px;
  position: relative;
}
.backgroundTransparent {
  background: transparent !important;
  width: 100%;
  height: 100%;
}

.progressBar {
  background: url('/src/assets/basecamp/donation/BARRE_PROG_VIERGE.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.hypeBar {
  background: url('/src/assets/basecamp/donation/BARRE_HYPE_VIERGE.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hypeBarPosition {
  position: absolute;
  height: 678px;
  top: 30px;
  left: 30px;
  width: 40.5px;
}
.hypeBarFill {
  width: 100%;
  background: #00CDF3;
  font-size: 40px;
  position: absolute;
  left: -1px;
  width: 42px;
  bottom: -1px;
}

.hypbarTurtle {
  position: absolute;
  top: -90px;
  left: -29px;
}
.hypeBarPerc {
  font-family: InterRegularBold;
  position: absolute;
  top: 25px;
  width: 50px;
  height: 109px;
  left: 20px;
  align-items: center;
  text-align: center;
  color: #4B328C !important;
  font-size: 25px;
  writing-mode: vertical-rl;
  text-orientation: upright;
}
.cursorPointer {
  cursor: pointer;
}

.donations {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  color: white;
  background: #0e101c;
  z-index: 1;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.progressBarPerc {
  font-family: InterRegularBold;
  position: absolute;
  top: 20px;
  width: 137px;
  right: 100px;
  text-align: center;
  color: #4B328C !important;
  font-size: 40px;
}

.progressBarText {
  font-family: InterRegular;
  position: absolute;
  top: 30px;
  left: 370px;
  color: white;
  /* height: 37px; */
  font-size: 28px;
}

.progressBarPosition {
  position: absolute;
  width: 1333px;
  top: 30px;
  left: 350px;
  height: 41px;
}
.progressBarFill {
  height: 100%;
  background: #00CDF3;
  font-size: 40px;
}


.hypeBarAnimate {
  animation-name: hypebar-full;
  animation-duration: 0.1s;
  animation-direction: alternate;
  animation-iteration-count: 150;
  animation-timing-function: linear;


}

@keyframes hypebar-full {
  from {
    background-color: #4B328C;
  }

  to {
    background-color: #00CDF3;
  }
}

.showDonation {
  opacity: 1;
}


.hideDonation {
  opacity: 0;
  top: -400px;
}

.showPalier{
  opacity: 1;
}

.hidePalier{
  opacity: 0;
  top: -400px;
}

.donationNotif {
  background: url('/src/assets/basecamp/donation/NOTIF_DONS_VIERGE.png');
  top: -805px;
  position: absolute;
  left: 85px;
  width: 330px;
  height: 213px;
  z-index: 3;
}

.donationNotifText {
  top: 144px;
  color: #00A59C;
  position: absolute;
  width: 100%;
  font-size: 50px;
  font-family: InterRegularBold;
}
.palierNotif {
  background: url('/src/assets/basecamp/donation/NOTIF_PALIER_VIERGE.png');
  top: -805px;
  left: 85px;
  position: absolute;
  width: 330px;
  height: 303px;
  z-index: 3;
}

.palierAmount {
  color: #FFF000;
  position: absolute;
  font-family: InterRegularBold;
  top: 130px;
  left: 16px;
  width: 300px;
  font-size: 44px;
}

.palierText {
  color: white;
  position: absolute;
  font-family: InterRegular;
  top: 185px;
  left: 0px;
  width: 329px;
  font-size: 20px;
  padding: 0 10px;
  line-height: 25px;
}

.palierNumber {
  position: absolute;
  top: 260px;
  left: 0px;
  font-size: 30px;
  width: 329px;
  color: #00A59C;
  font-family: InterRegularBold;
}

.testButtons {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

