@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,300;1,700&display=swap');

@font-face {
  font-family: 'Exo';
  src: local('Exo'), url(src/assets/nationwars/fonts/Exo2-Regular.otf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'ExoBold';
  src: local('ExoBold'), url(src/assets/nationwars/fonts/Exo2-Bold.otf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'HelveticaBold';
  src: local('HelveticaBold'), url(src/assets/fonts/Helvetica/HelveticaNeueLTStd-Bd.otf) format('truetype');
  font-display: swap
}
@font-face {
  font-family: 'HelveticaMedium';
  src: local('HelveticaBold'), url(src/assets/fonts/Helvetica/HelveticaNeueLTStd-Md.otf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'ApotekBlack';
  src: local('ApotekBlack'), url(src/assets/basecamp/fonts/Apotek-Black.otf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'ApotekRegular';
  src: local('ApotekRegular'), url(src/assets/basecamp/fonts/Apotek-Regular.otf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'), url(src/assets/basecamp/fonts/Inter-Regular.ttf) format('truetype');
  font-display: swap
}

@font-face {
  font-family: 'InterRegularBold';
  src: local('InterRegularBold'), url(src/assets/basecamp/fonts/Inter-ExtraBold.ttf) format('truetype');
  font-display: swap
}


body {
  margin: 0;
  font-family: CopperplateRegular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
}
body {
  background-color: black;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
}

/* react-transition-group fades */
.mainFade-appear,
.mainFade-enter {
  opacity: 0;
  z-index: 1;
}

.mainFade-appear-active,
.mainFade-enter.mainFade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.mainFade-exit {
  opacity: 1;
}

.mainFade-exit.mainFade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.ReactCrop__image {
  max-width: 1000px !important;
}


button {
  margin: 0 auto !important;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}
