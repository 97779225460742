.photo {
  width: 100px;
}

.pageContainer {
  background: #0e101c !important;
  padding-left: 310px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 400px;
  color: white;
  min-height: 100vh;
}

.modal {
  width: 30%;
}

/* .membersListContainer {
  width: 200%;
} */

.cursorPointer {
  cursor: pointer;
}

.title {
  font-size: 30px;
  text-align: center;
  color: white;
}

.link {
  width: fit-content;
}

.icon {
  width: fit-content;
  margin: 0 auto;

}
