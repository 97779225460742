.componentContainer {
  position: absolute;
}
.playerSelectionImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.timerContainer {
  position: absolute;
  top: 47%;
  left: 50%;
  color: white;
  transform: translate(-50%, -75%);
  align-items: center;
  font-size: 50px;
  font-family: ExoBold;
}
