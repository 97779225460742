.pageContainer {
  background: #0e101c !important;
  padding-left: 310px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 400px;
  color: white;
}
.cursorPointer {
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: white;
}

.title {
  font-size: 30px;
  text-align: center;
  color: white;
}

.nationRender {
  display: flex;
  justify-content: space-evenly;
}

.team1 {
  border: 1px solid blue;
  padding: 2px;
  cursor: pointer;
}

.team2 {
  border: 1px solid red;
  padding: 2px;
  cursor: pointer;
}

.teamStream {
  border: 1px solid cyan;
  padding: 2px;
  cursor: pointer;
}
