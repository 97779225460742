.notifs {
  width: 252px;
  height: 122px;
  position: absolute;
  left: 107px;
  top: 45px;
  /* background-image: red; */
  background: red;
  animation: donationNotif cubic-bezier(.17,.67,.59,1.3) 500ms;
}

.notifsAmount {
  position: absolute;
  left: 13px;
  top: 85px;
  width: 222px;
  height: 40px;
  color: #150538;
  font-family: "Helvetica93";
  margin: 0;
  font-size: 28px;
  line-height: 45px;
  text-align: center;
}
.notifsName {
  position: absolute;
  font-family: "Helvetica75";
  left: 13px;
  top: 37px;
  width: 222px;
  height: 40px;
  margin: 0;
}
