.pageContainer {
  background: #0e101c !important;
  padding-left: 310px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 400px;
}

.pageTitle {
  color: white !important;
  font-size: 30px;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}
.marginLeft {
  margin-left: 20px;
}
.marginRight {
  margin-right: 20px;
}

.link {
  text-decoration: none;
  color: white;

}
.width30 {
  width: 33%;
}

.cursorPointer {
  cursor: pointer;
}
