.pageContainer {
  width: 1920px;
  height: 1080px;
  position: relative;
  background: url("src/assets/nationwars/FOND_VETO.png");
  z-index: 1;
}
.mapsListContainer {
  position: absolute;
  bottom: 71px;
  left: 154.5px;
}
/* FOND_VETO.png */

.titreEtLignes {
  background: url("src/assets/nationwars/COUCHE_TITRE_ET_LIGNES.png");
}
