.alertElement {
  width: 400px;
}

.alertContainer {
  position: fixed;
  bottom: 50px;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
}
