.pageContainer {
  color: black;
  width: 100vw;
  height: 100vh;
}

.tagCloud {
  height: 70%;
  width: 50%;
  margin: auto;
  margin-top: 15%;
}
.tagCloud span {
  cursor: pointer;
}
