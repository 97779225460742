.pageContainer, .noBackground {
  background: transparent !important;
  width: 1920px;
  height: 1080px;
  position: relative;
}

.cursorPointer {
  cursor: pointer;
}

.donations {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  color: white;
  background: #0e101c;
  z-index: 1;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.pageContainer {
  background-size: cover;
  width: 1920px;
  height: 1080px;
  position: relative;
  line-height: 35px;
  background: #32CD32;
}

.questionBackground {
  background: url('/src/assets/basecamp/poll/bg.png');
  width: 100%;
  height: 100%;
}

.question {
  position: absolute;
  left: 100px;
  width: 1719px;
  top: 685px;
  height: 100px;
  text-align: center;
  /* z-index: 4; */
  font-size: 30px;
  color: #4B328C;
  font-family: HelveticaBold;
}
.answerAContainer,.answerBContainer,.answerCContainer,.answerDContainer {
  color: white;
}
.answerAContainer {
  background: url('/src/assets/basecamp/poll/AN.png');
  width: 1920px;
  height: 1080px;
  position: absolute;
}
.answerBContainer {
  background: url('/src/assets/basecamp/poll/BN.png');
  width: 1920px;
  height: 1080px;
  position: absolute;

}
.answerCContainer {
  background: url('/src/assets/basecamp/poll/CN.png');
  width: 1920px;
  height: 1080px;
  position: absolute;

}
.answerDContainer {
  background: url('/src/assets/basecamp/poll/DN.png');
  width: 1920px;
  height: 1080px;
  position: absolute;

}

.answerA,.answerB,.answerC,.answerD {
  position: absolute;
  font-size: 29px;
  font-family: HelveticaMedium;
  text-align: center;
  width: 650px;
}

.answerA {
  left: 220px;
  top: 860px;
}
.answerB {
  left: 1105px;
  top: 860px;
}
.answerC {
  left: 220px;
  top: 965px;
}
.answerD {
  left: 1105px;
  top: 965px;
}

.percentageA,.percentageB,.percentageC,.percentageD {
  position: absolute;
  font-size: 24px;
  font-family: HelveticaBold;
  font-weight: 900;
  width: 115px;
  text-align: center;
}

.percentageA {
  top: 863px;
  left: 825px;
}

.percentageB {
  top: 863px;
  left: 1710px;
}

.percentageC {
  top: 965px;
  left: 825px;
}

.percentageD {
  top: 965px;
  left: 1710px;

}
