.mapContainer {
  width: 200px;
  height: 200px;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  border: 3px solid #22f0f1;
  border-radius: 27px;
}

.arrow, .cross {
  position: absolute;
  display: none;
  left: 0;
  right: 0;
  width: 200px;
}

.pointer {
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}
