.title {
  font-size: 30px;
  text-align: center;
  color: white;
}

.imagePickerContainer {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 500px;
  margin: 30px auto;
}
