.imageHolder {
  margin: 0;
  text-align: center;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px dashed #767679;
  box-sizing: border-box;
  border-radius: 30px;
  cursor: pointer;
  background: #919191 0% 0% no-repeat padding-box;
}
.previewCropped {
  display: flex;
}

.cropperContainer {
  height: 100%;
  z-index: 2;
}

.imagePreview {
  width: 100%;
  border-radius: 30px;
}

.croppedImage {
  display: none;
  height: 316px;
  width: 500px;
}

.uploadImage {
  display: flex;
  height: 100%;
}

/* .croppedImageContainer {
  display: none;
  flex: 2;
  margin-left: 20px;
} */

.center_image {
  border-radius: 12px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plusicon {
  left: 6px;
  top: 12px;
  border: none;
  background-color: #919191 0% 0% no-repeat padding-box;
  color: #2b2b2b;
}

.plusicon:focus {
  outline: none;
}

.addphoto {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  float: left;
  width: 100%;
  margin: 10px 0 0 0;
}

.gameImageInput {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  cursor: pointer;
  opacity: 0;
}

.uploadedImage {
  max-height: 800px;
  max-width: 1024px;
}

.error {
  border: 2px solid red;
}

.errorText {
  color: red;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}
