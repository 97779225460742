
.elementDisplayed {
  opacity: 0.8;
}
.elementNotDisplayed {
  opacity: 0.2;
}
.title {
  font-size: 30px;
  text-align: center;
  color: white;
}
.description {
  font-size: 20px;
  color: chartreuse;
}

.photo {
  height: 50px;
}
