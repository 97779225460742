.pageContainer {
  background-size: cover;
  width: 1920px;
  height: 1080px;
  position: relative;
  line-height: 35px;
  background: #32CD32;
}

.hidePerc {
  width: 150px;
  height: 80px;
  transform: translate(-10%, -70%);
  z-index: 2;
  background: #32CD32;
}

.questionBackground {
  background: url('/src/assets/votes/jmqcm4/bg.png');
  width: 100%;
  height: 100%;
}

.question {
  position: absolute;
  left: 70px;
  width: 1780px;
  top: 690px;
  height: 80px;
  text-align: center;
  /* z-index: 4; */
  font-size: 30px;
  color: #eaf71b;
  font-family: HelveticaBold;
}
.answerAContainer,.answerBContainer,.answerCContainer,.answerDContainer {
  color: #eaf71b;
}
.answerAContainer {
  background: url('/src/assets/votes/jmqcm4/AN.png');
  width: 1920px;
  height: 1080px;
  position: absolute;
}
.answerBContainer {
  background: url('/src/assets/votes/jmqcm4/BN.png');
  width: 1920px;
  height: 1080px;
  position: absolute;

}
.answerCContainer {
  background: url('/src/assets/votes/jmqcm4/CN.png');
  width: 1920px;
  height: 1080px;
  position: absolute;

}
.answerDContainer {
  background: url('/src/assets/votes/jmqcm4/DN.png');
  width: 1920px;
  height: 1080px;
  position: absolute;

}

.answerA,.answerB,.answerC,.answerD {
  position: absolute;
  font-size: 29px;
  font-family: HelveticaMedium;
  text-align: center;
  width: 630px;
}

.answerA {
  left: 130px;
  top: 820px;
}
.answerB {
  left: 1048px;
  top: 820px;
}
.answerC {
  left: 130px;
  top: 940px;
}
.answerD {
  left: 1048px;
  top: 940px;
}

.percentageA,.percentageB,.percentageC,.percentageD {
  position: absolute;
  font-size: 24px;
  font-family: HelveticaBold;
  font-weight: 900;
  width: 115px;
  text-align: center;
}

.percentageA {
  top: 820px;
  left: 822px;
}
.percentageB {
  top: 820px;
  left: 1740px;

}
.percentageC {
  top: 940px;
  left: 822px;
}
.percentageD {
  top: 940px;
  left: 1740px;

}
