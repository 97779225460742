.componentContainer {
  width: 566px;
  margin: 0 auto;
  line-height: 75px;
  position: absolute;
  top: 131px;
  left: 677px;
}

.image {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.imageContainer {
  width: 20%;
}

.panelTable {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #22f0f1;
  font-family: ExoBold;
}

.pickLine {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.mapBackgroundSingle {
  background: url("src/assets/nationwars/VS_1V1.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 57px;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.mapBackgroundDuo {
  background: url("src/assets/nationwars/VS_2V2.png");
  background-repeat: no-repeat;
  height: 57px;
  margin-bottom: 7px;
  width: 105%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.mapBackgroundAce {
  background: url("src/assets/nationwars/VS_ACE.png");
  background-repeat: no-repeat;
  height: 57px;
  margin-bottom: 7px;
  width: 107%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.playerContainer {
  width: 40%;
}
