body {
  background: transparent !important;
  padding: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  padding-bottom: 200px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

form {
  max-width: 70%;
  margin: 0 auto;
}

h1 {
  font-weight: 100;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

p {
  color: #bf1650;
}

/* p::before {
  display: inline;
  content: "⚠ ";
} */

.userContainer {
  position: absolute;
  top: 0;
  right: 0;
}

.userName {
  color: white;
  font-size: 28px;
  line-height: 60px;
  margin-right: 10px;
}
input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 7px;
  margin-top: 5px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

button[type='submit'],
input[type='submit'] {
  background: #33d34d;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

button[type='submit']:hover,
input[type='submit']:hover {
  background: #02611a;
}

button[type='submit']:active,
input[type='button']:active,
input[type='submit']:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type='button']:hover {
  transition: 0.3s all;
}

button[type='submit'],
input[type='button'],
input[type='submit'] {
  -webkit-appearance: none;
}

button[type='button'] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

pre {
  color: white;
}

hr {
  margin-top: 30px;
}

button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.modal-content {
  width: 150% !important;
  background: #0e101c !important;
  color: rgb(201, 231, 93) ;
}

input, label, textarea {
  display: inline-block !important;
  font-size: 14px !important;
}

button {
  color: white;
  text-transform: uppercase !important;
  border: none !important;
  margin-top: 40px;
  padding: 20px !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 10px;
}

.deleteButton {
  padding: 0 !important;
  background-color: unset !important;
  color: unset !important;
}

.link {
  color: white !important;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  text-decoration: none !important;
}
.buttonRed {
  background: #720202;
}
.buttonRed:hover {
  background: #520101;
}

.buttonBlue {
  background: #00f0f8;

}
.buttonBlue:hover {
  background: #00a4aa;

}
.buttonPink {
  background: #ec5990;
}
.buttonPink:hover {
  background: #af3864;
}
.buttonYellow {
  background: #1323ff;
}
.buttonYellow:hover {
  background: #0c17ad;
}
