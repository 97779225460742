.pageContainer {
  background: #0e101c !important;
  padding-left: 310px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 400px;
  color: white;
}

.imagePickerContainer {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  width: 500px;
  margin: 30px auto;
}

.oldImage {
  width: 200px;
}


.title {
  font-size: 30px;
  color: white;
  text-align: center;
}
