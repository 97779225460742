.componentContainer {
  position: absolute;
  width: 90%;
  height: 200px;
  font-size: 25px;
  top: 450px;
  left: 5%;
  justify-content: space-between;
  display: flex;
  color: #22f0f1;
  font-family: ExoBold;
}

.playersContainer {
  position: absolute;
  width: 20%;
  z-index: 1;
}

.playersContainer > div {
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
}

