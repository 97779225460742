.container {
  width: 1920px;
  height: 1080px;
  display: flex;
  justify-content: space-between;
}

.flag {
  width: 500px;
  position: absolute;
  top: 25px;
  z-index: 0;
}

.playersContainer {
  margin: -100px 0;
}

.captainContainer, .pickedPlayerContainer {
  margin: 20px auto;
  background-color: black;
  width: 80%;
  font-size: 22px;
  border-radius: 5px;
  color: #22f0f1;
  border: 1px solid #22f0f1;
  padding: 10px 30px;
}
.captainContainer {
  font-family: Exo;
  color: green;
}
.pickedPlayerContainer {
  font-family: Exo;
  color: yellow;
}

.vetoPickContainer {
  width: 430px;
  height: 125px;
  position: absolute;
  top: 433px;

}

.veto {
  position: absolute;
  height: 125px;
  width: 275px;
  background: url("src/assets/nationwars/VETO_BUTTON.png");
}

.pick {
  position: absolute;
  background: url("src/assets/nationwars/PICK_BUTTON.png");
  height: 125px;
  width: 275px;
  left: 153px;
}

.leftContainer {
  left: 79px;

}
.rightContainer {

  right: 79px;
}
