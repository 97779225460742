.container {
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1080px;
  color: white;
  font-size: 100px;
  text-align: center;
  font-family: ExoBold;
}
