.center {
  align-items: center !important;
  display: flex;
  height: 100%;
  justify-content: center;
}

.pageContainer {
  height: 100vh;
}

.button {
  transform: translate(0, -50%) !important;

}
