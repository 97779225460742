.container {
  width: fit-content;
  margin: 0 auto;
}
.mapContainer {
  width: 200px;
  height: 200px;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  position: relative;
}

.marginTop {
  margin-top: 18px;
}
.image {
  width: 100%;
  height: 100%;
  border: 3px solid red;
}

.arrow,
.cross {
  position: absolute;
  display: none;
  left: 0;
  right: 0;
  width: 200px;
}
